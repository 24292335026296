import React, { useEffect, useState } from "react";
import { useModal } from "../context/ModalContext";
import { utils, write } from "xlsx";
import EditNameModal from "../modals/EditNameModal";
import ConfirmDeleteModal from "../modals/ConfirmDeleteModal";
import ReuploadModal from "../modals/ReuploadModal";
import { useGetDataFileByNameQuery } from "../slices/dataFilesApiSlice";
import { TbAlertCircle, TbCaretDownFilled, TbCaretRightFilled, TbSearch, TbX } from "react-icons/tb";
import useCynomiData from "../hooks/useCynomiData";

const saveArrayToExcel = (array, name) => {
  const ws = utils.aoa_to_sheet(array);
  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, "Sheet1");
  const wbout = write(wb, { bookType: "xlsx", type: "binary" });
  const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = `${name}.xlsx`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

function s2ab(s) {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
}

const CategorySection = ({
  title,
  data,
  isLoading,
  error,
  expandedGroups,
  setExpandedGroups,
  chosenItem,
  onItemClick,
  searchQuery,
  onSearchChange,
  onClearSearch,
  isOpen,
}) => {
  const { showModal } = useModal();
  const { groupedItems = [] } = data;
  const [currentAction, setCurrentAction] = useState(null); // 'download' or 'reupload'
  const [currentItem, setCurrentItem] = useState(null);

  const { data: excelData, isLoading: isFetchingExcel } = useGetDataFileByNameQuery(currentItem, {
    skip: !currentItem,
  });
  const { cynomiCsvData, isLoading: isCynomiLoading } = useCynomiData();

  useEffect(() => {
    if (currentItem && excelData && !isFetchingExcel && excelData.data && !isCynomiLoading && cynomiCsvData) {
      const data = excelData.data;

      if (!Array.isArray(data) || data.length === 0) {
        console.error("Invalid data format.");
        if (currentAction === "download") {
          saveArrayToExcel(data, currentItem);
        }
        setCurrentItem(null);
        setCurrentAction(null);
        return;
      }

      if (currentAction === "download") {
        // Process and download the file
        const header = data[0];
        const aiMatchUidIndex = header.indexOf("AI Match UID");
        if (aiMatchUidIndex === -1) {
          console.error("AI Match UID column not found.");
          saveArrayToExcel(data, currentItem);
        } else {
          const userMappingIndex = header.indexOf("User Mapping");
          const hasUserMapping = userMappingIndex !== -1;
          const transformedData = data.map((rowOrig, rowIndex) => {
            const row = [...rowOrig];
            if (rowIndex === 0) {
              if (hasUserMapping) {
                row.splice(userMappingIndex + 1, 0, "User Mapping Policies");
              }
              return row;
            }

            if (hasUserMapping) {
              const userMapping = row[userMappingIndex];
              let userMappingArr = [];
              if (typeof userMapping === 'string') {
                userMappingArr = userMapping.split(/, ?/)
              } else if (Array.isArray(userMapping)) {
                userMappingArr = userMapping;
              }
              const userMappingPolicies = userMappingArr.map(
                uid => cynomiCsvData.find(r => r.ID === uid)?.Policy || "[Missing]");
              row.splice(userMappingIndex + 1, 0, userMappingPolicies.join(', '));
            }

            const aiMatchUidCell = `[${row[aiMatchUidIndex]}]`;
            let aiMatchArray;

            if (typeof aiMatchUidCell === "string") {
              try {
                aiMatchArray = JSON.parse(aiMatchUidCell);
              } catch (error) {
                console.error(`Error parsing AI Match UID cell at row ${rowIndex + 1}:`, error);
                aiMatchArray = [];
              }
            } else if (Array.isArray(aiMatchUidCell)) {
              aiMatchArray = aiMatchUidCell;
            } else {
              aiMatchArray = [];
            }

            const ids = aiMatchArray.map((item) => item.ID).filter(Boolean).join(", ");
            row[aiMatchUidIndex] = ids;
            return row;
          });

          saveArrayToExcel(transformedData, currentItem);
        }
      } else if (currentAction === "reupload") {
        // Pass the data to the ReuploadModal
        console.log(`Reuploading ${currentItem}... excel data: ${excelData.data}`);
        showModal(<ReuploadModal frameworkName={currentItem} excelData={excelData} />);
      }

      setCurrentItem(null);
      setCurrentAction(null);
    }
  }, [currentItem, excelData, isFetchingExcel, currentAction, showModal]);

  const handleItemClick = (item, action) => {
    if (action === "edit") {
      showModal(<EditNameModal frameworkName={item} />);
    } else if (action === "delete") {
      showModal(<ConfirmDeleteModal frameworkName={item} />);
    } else if (action === "download" || action === "reupload") {
      setCurrentAction(action);
      setCurrentItem(item);
    }
  };

  return (
    <div>
      <div className="search-bar">
        <TbSearch className="search-icon" size={16} />
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => onSearchChange(e.target.value)}
        />
        {searchQuery && (
          <TbX
            className="clear-icon"
            onClick={onClearSearch}
            size={16}
            role="button"
            tabIndex={0}
            aria-label="Clear search"
          />
        )}
      </div>

      {isLoading ? (
        <div className="loading-frameworks">
          <p>Loading {title.toLowerCase()}...</p>
        </div>
      ) : error ? (
        <div className="error-message">
          <TbAlertCircle size={20} color="#dc3545" />
          <span>{error?.data?.message || error.error}</span>
        </div>
      ) : (
        <div className="framework-list-body">
          {groupedItems.map((group) => (
            <div key={group.group} className="framework-group">
              <div
                className={`group-header ${expandedGroups[group.group] ? "active" : ""}`}
                aria-expanded={expandedGroups[group.group] ? "true" : "false"}
              >
                <div
                  className="group-header-right"
                  onClick={() =>
                    setExpandedGroups((prev) => ({
                      ...prev,
                      [group.group]: !prev[group.group],
                    }))
                  }
                  tabIndex={0}
                  onKeyPress={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      setExpandedGroups((prev) => ({
                        ...prev,
                        [group.group]: !prev[group.group],
                      }));
                    }
                  }}
                >
                  <span className="group-toggle">
                    {expandedGroups[group.group] ? <TbCaretDownFilled /> : <TbCaretRightFilled />}
                  </span>
                  <span className="group-name">{group.group}</span>
                </div>
              </div>
              {expandedGroups[group.group] && (
                <ul>
                  {group.items.map((item) => (
                    <li key={`${item}`} className="framework-item">
                      <button
                        className={`framework-button ${
                          chosenItem === item ? "active" : ""
                        }`}
                        onClick={() => onItemClick(item, group.group)}
                      >
                        {item}
                      </button>
                      {isOpen && (
                        <div className="action-links">
                          <button className="link-button" onClick={() => handleItemClick(item, "reupload")}>
                            Reupload
                          </button>
                          <span>|</span>
                          <button className="link-button" onClick={() => handleItemClick(item, "delete")}>
                            Delete
                          </button>
                          <span>|</span>
                          <button className="link-button" onClick={() => handleItemClick(item, "edit")}>
                            Edit
                          </button>
                          <span>|</span>
                          <button className="link-button" onClick={() => handleItemClick(item, "download")}>
                            Download
                          </button>
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CategorySection;
